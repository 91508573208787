<template>
  <section :class="$style.bonusTransaction">
    <div :class="$style.count">
      <span>Сумма бонусных транзакций:</span>
      <span>{{ bonusTransactionsCount }}&#8381;</span>
    </div>
    <el-table :data="bonusTransactions" stripe>
      <el-table-column prop="comment" label="Название"></el-table-column>
      <el-table-column prop="createdAt" label="Дата">
        <template slot-scope="scope">
          {{ formatDate(scope.row.createdAt) }}
        </template></el-table-column
      >
      <el-table-column prop="value" label="Сумма"
        ><template slot-scope="scope">
          {{ scope.row.value }}&#8381;
        </template></el-table-column
      >
    </el-table>
    <el-pagination
      v-if="totalPages > 1"
      :class="$style.pagination"
      layout="prev, pager, next"
      :page-size="limit"
      :total="total"
      :current-page.sync="page"
      @current-change="getList"
      background
    >
    </el-pagination>
  </section>
</template>

<script>
import delivery from '@/delivery'

import { formatDate } from '@/helpers/index.js'

export default {
  data() {
    return {
      bonusTransactions: [],
      limit: 20,
      page: 1,
      total: 0,
      bonusTransactionsCount: 0
    }
  },
  computed: {
    totalPages() {
      return Math.ceil(this.total / this.limit)
    }
  },
  async created() {
    await Promise.all([this.getList(), this.getBonusTransactionsCount()])
  },
  methods: {
    async getList() {
      const loading = this.$loading({
        lock: true
      })

      const query = {
        limit: this.limit,
        page: this.page,
        orderBy: 'created_at',
        orderDir: 'desc'
      }

      const { value, error } =
        await delivery.AddwineCore.DealersActions.getBonusTransactions(
          this.$route.params.id,
          query
        )

      loading.close()

      if (error) return

      this.bonusTransactions = value.data
      this.total = value.meta.count
    },
    async getBonusTransactionsCount() {
      const loading = this.$loading({
        lock: true
      })

      const { value, error } =
        await delivery.AddwineCore.DealersActions.getBonusTransactionsCount(
          this.$route.params.id
        )

      loading.close()

      if (error) return

      this.bonusTransactionsCount = value
    },
    formatDate(date) {
      return formatDate(date)
    }
  }
}
</script>

<style lang="scss" module>
.bonusTransaction {
  padding: 1rem;

  .count {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    margin-bottom: 1rem;

    & > span:last-child {
      @include bold-default;
    }
  }

  .pagination {
    @include pagination;
  }
}
</style>
